import { useQuery } from "react-query";
import { balanceApi } from "../../services/balance.js";

export const useBalancesList = (params) => {
  const { isError, isLoading, data, refetch } = useQuery(
    ["balances", params],
    () => balanceApi.fetchBalanceList(params),
    {
      staleTime: Infinity,
      onError: (error) => {
        console.log(error);
      },
      select: ({ data }) => {

        let balancesList = data?.data?.data;
        let meta = data?.data?.meta;
        return { balancesList, meta };
      },
    }
  );
  return {
    isLoading,
    balancesList: data?.balancesList,
    meta: data?.meta,
    refetch,
  };
};
