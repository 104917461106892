import dashboardIcon from "../assets/images/dashboard.svg";
import Flex from "../components/general/Flex.jsx";
import Typography from "../components/moleculs/Typography.jsx";
import users_icon from "../assets/images/users-icon.svg";
import prime_users_icon from "../assets/images/prime_users.svg";
import layers_icon from "../assets/images/layers-icon.svg";
import expected_icon from "../assets/images/expected-menu-icon.svg";
import orders_check_icon from "../assets/images/orders-check.svg";
import orders_filed_icon from "../assets/images/failed-orders.svg";
import finance_icon from "../assets/images/finance-icon.svg";
import balance_icon from "../assets/images/balance-icon.svg";
import reports_icon from "../assets/images/reports-icon.svg";
import payment_icon from "../assets/images/payment-icon.svg";
import bonus_icon from "../assets/images/bonus-tab-icon.svg";
import consignment_icon from "../assets/images/consignment.svg";
import receive_orders_icon from "../assets/images/receive-orders.svg";
import members_icon from "../assets/images/members-and-roles.svg";
import roles_icon from "../assets/images/roles-icon.svg";
import actions_history_icon from "../assets/images/actions_history.svg";
import content_icon from "../assets/images/content.svg";
import banner_icon from "../assets/images/banner.svg";
import blog_icon from "../assets/images/blog.svg";
import shops_icon from "../assets/images/shops.svg";
import warehouses_icon from "../assets/images/warehouses.svg";
import story_icon from "../assets/images/story.svg";
import notification_icon from "../assets/images/notification-box.svg";
import best_offers_icon from "../assets/images/best_offers.svg";
import settings_icon from "../assets/images/settings-icon.svg";
import logout_icon from "../assets/images/log-out-icon.svg";
import onex_logo from "../assets/images/onex_logo_closed.svg";
import app_versions from "../assets/images/api-versions.svg";
import tariffs_icon from "../assets/images/line-chart-up.svg";
import customized_pricing_icon from "../assets/images/bar-chart-square.svg";
import lostIcon from "../assets/images/box-remove.svg";

import { Tag } from "antd";

function getItem(label, key, icon, children, type, component) {
  return {
    key,
    icon,
    children,
    label,
    type,
    component,
  };
}

const permissions = JSON.parse(localStorage.getItem("permissions")) || [];
const name = localStorage.getItem("name");
const email = localStorage.getItem("email");
const items = [
  getItem(
    "",
    "/",
    <div className={"home_logo"}>
      <img alt={"home"} src={onex_logo} />
    </div>
  ),
  permissions["dashboard"]
    ? getItem("", "dash", <img alt={"dashboard"} src={dashboardIcon} />, [
        getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"dashboard"}
              src={dashboardIcon}
              style={{ margin: "10px 12px 0 0" }}
            />
            <Typography
              text={"Dashboard"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
          </Flex>,
          "dashboard"
        ),
      ])
    : null,
  permissions["recipient"]
    ? getItem("", "rec", <img alt={"recipients"} src={users_icon} />, [
        getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"users"}
              src={users_icon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Customers"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
          </Flex>,
          "customers"
        ),
        getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"prime_users"}
              src={prime_users_icon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Prime Users"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
          </Flex>,
          "prime-users"
        ),
        permissions["test-user"]
          ? getItem(
              <Flex
                justify={"flex-start"}
                cursor={"pointer"}
                padding={"8px 0 0 0"}
              >
                <img
                  alt={"test_users"}
                  src={prime_users_icon}
                  style={{ margin: "8px 12px 0 0" }}
                />
                <Typography
                  text={"Test Users"}
                  size={"14px"}
                  variant={"p"}
                  color={"#3B5166"}
                  lineHeight={"20px"}
                  padding={"10px 0 0 0"}
                />
              </Flex>,
              "test-users"
            )
          : null,
      ])
    : null,
  permissions["order"]
    ? getItem("", "ord", <img alt={"orders"} src={layers_icon} />, [
        permissions["expected"]
          ? getItem(
              <Flex
                justify={"flex-start"}
                cursor={"pointer"}
                padding={"8px 0 0 0"}
              >
                <img
                  alt={"order"}
                  src={expected_icon}
                  style={{ margin: "8px 12px 0 0" }}
                />
                <Typography
                  text={"Expected"}
                  size={"14px"}
                  variant={"p"}
                  color={"#3B5166"}
                  lineHeight={"20px"}
                  padding={"10px 0 0 0"}
                />
              </Flex>,
              "expected"
            )
          : null,
        permissions["order"]
          ? getItem(
              <Flex
                justify={"flex-start"}
                cursor={"pointer"}
                padding={"8px 0 0 0"}
              >
                <img
                  alt={"orders"}
                  src={layers_icon}
                  style={{ margin: "8px 12px 0 0" }}
                />
                <Typography
                  text={"Orders"}
                  size={"14px"}
                  variant={"p"}
                  color={"#3B5166"}
                  lineHeight={"20px"}
                  padding={"10px 0 0 0"}
                />
              </Flex>,
              "orders"
            )
          : null,
        permissions["order"]
          ? getItem(
              <Flex
                justify={"flex-start"}
                cursor={"pointer"}
                padding={"8px 0 0 0"}
              >
                <img
                  alt={"orders_check"}
                  src={orders_check_icon}
                  style={{ margin: "8px 12px 0 0" }}
                />
                <Typography
                  text={"Orders check"}
                  size={"14px"}
                  variant={"p"}
                  color={"#3B5166"}
                  lineHeight={"20px"}
                  padding={"10px 0 0 0"}
                />
              </Flex>,
              "orders-check"
            )
          : null,
        permissions["order"]
          ? getItem(
              <Flex
                justify={"flex-start"}
                cursor={"pointer"}
                padding={"8px 0 0 0"}
              >
                <img
                  alt={"failed_orders"}
                  src={orders_filed_icon}
                  style={{ margin: "8px 12px 0 0" }}
                />
                <Typography
                  text={"Failed orders"}
                  size={"14px"}
                  variant={"p"}
                  color={"#3B5166"}
                  lineHeight={"20px"}
                  padding={"10px 0 0 0"}
                />
              </Flex>,
              "orders-failed"
            )
          : null,
      ])
    : null,
  permissions["lost-order"]
    ? getItem("", "lost", <img alt={"lost_order"} src={lostIcon} />, [
        getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"lost_order"}
              src={lostIcon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Lost orders"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
          </Flex>,
          "lost-orders"
        ),
      ])
    : null,
  // permissions["tariff"]
  //   ? getItem("", "tar", <img alt={"tariffs"} src={tariffs_icon} />, [
  //       getItem(
  //         <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
  //           <img
  //             alt={"tariffs"}
  //             src={tariffs_icon}
  //             style={{ margin: "8px 12px 0 0" }}
  //           />
  //           <Typography
  //             text={"Tariffs"}
  //             size={"14px"}
  //             variant={"p"}
  //             color={"#3B5166"}
  //             lineHeight={"20px"}
  //             padding={"10px 0 0 0"}
  //           />
  //         </Flex>,
  //         "tariffs"
  //       ),
  //       getItem(
  //         <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
  //           <img
  //             alt={"pricing"}
  //             src={customized_pricing_icon}
  //             style={{ margin: "8px 12px 0 0" }}
  //           />
  //           <Typography
  //             text={"Customized pricing"}
  //             size={"14px"}
  //             variant={"p"}
  //             color={"#3B5166"}
  //             lineHeight={"20px"}
  //             padding={"10px 0 0 0"}
  //           />
  //         </Flex>,
  //         "customized-pricing"
  //       ),
  //     ])
  //   : null,
  permissions["balance"] && permissions["bonus"] && permissions["report"]
    ? getItem("", "bal", <img alt={"bal_bon_rep"} src={finance_icon} />, [
        permissions["balance"]
          ? getItem(
              <Flex
                justify={"flex-start"}
                cursor={"pointer"}
                padding={"8px 0 0 0"}
              >
                <img
                  alt={"balance"}
                  src={balance_icon}
                  style={{ margin: "8px 12px 0 0" }}
                />
                <Typography
                  text={"Balance"}
                  size={"14px"}
                  variant={"p"}
                  color={"#3B5166"}
                  lineHeight={"20px"}
                  padding={"10px 0 0 0"}
                />
              </Flex>,
              "balance"
            )
          : null,
        permissions["report"]
          ? getItem(
              <Flex
                justify={"flex-start"}
                cursor={"pointer"}
                padding={"8px 0 0 0"}
              >
                <img
                  alt={"reports"}
                  src={reports_icon}
                  style={{ margin: "8px 12px 0 0" }}
                />
                <Typography
                  text={"Reports"}
                  size={"14px"}
                  variant={"p"}
                  color={"#3B5166"}
                  lineHeight={"20px"}
                  padding={"10px 0 0 0"}
                />
              </Flex>,
              "reports"
            )
          : null,
        permissions["report"]
          ? getItem(
              <Flex
                justify={"flex-start"}
                cursor={"pointer"}
                padding={"8px 0 0 0"}
              >
                <img
                  alt={"online_payment"}
                  src={payment_icon}
                  style={{ margin: "8px 12px 0 0" }}
                />
                <Typography
                  text={"Online payment"}
                  size={"14px"}
                  variant={"p"}
                  color={"#3B5166"}
                  lineHeight={"20px"}
                  padding={"10px 0 0 0"}
                />
              </Flex>,
              "online-payment"
            )
          : null,
        permissions["bonus"]
          ? getItem(
              <Flex
                justify={"flex-start"}
                cursor={"pointer"}
                padding={"8px 0 0 0"}
              >
                <img
                  alt={"bonus"}
                  src={bonus_icon}
                  style={{ margin: "8px 12px 0 0" }}
                />
                <Typography
                  text={"Bonus"}
                  size={"14px"}
                  variant={"p"}
                  color={"#3B5166"}
                  lineHeight={"20px"}
                  padding={"10px 0 0 0"}
                />
              </Flex>,
              "bonus"
            )
          : null,
      ])
    : null,
  permissions["parcel"]
    ? getItem("", "par", <img alt={"parcel"} src={consignment_icon} />, [
        getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"consignment"}
              src={consignment_icon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Consignment"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
          </Flex>,
          "consignment"
        ),
      ])
    : null,
  permissions["order"]
    ? getItem(
        "",
        "rec_ord",
        <img alt={"rec_ord"} src={receive_orders_icon} />,
        [
          getItem(
            <Flex
              justify={"flex-start"}
              cursor={"pointer"}
              padding={"8px 0 0 0"}
            >
              <img
                alt={"consignment"}
                src={receive_orders_icon}
                style={{ margin: "8px 12px 0 0" }}
              />
              <Typography
                text={"Receive orders"}
                size={"14px"}
                variant={"p"}
                color={"#3B5166"}
                lineHeight={"20px"}
                padding={"10px 0 0 0"}
              />
            </Flex>,
            "receive-orders"
          ),
        ]
      )
    : null,
  permissions["role"]
    ? getItem("", "role", <img alt={"role"} src={members_icon} />, [
        getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"members"}
              src={members_icon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Members"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
          </Flex>,
          "members"
        ),
        getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"roles"}
              src={roles_icon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Roles"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
          </Flex>,
          "roles"
        ),
      ])
    : null,
  permissions["role"]
    ? getItem("", "act", <img alt={"action"} src={actions_history_icon} />, [
        getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"action"}
              src={actions_history_icon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Actions history"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
          </Flex>,
          "actions-history"
        ),
      ])
    : null,
  getItem("", "content", <img alt={"slides"} src={content_icon} />, [
    permissions["header-message"]
      ? getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"header"}
              src={banner_icon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Header"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
          </Flex>,
          "banners"
        )
      : null,
    permissions["slider"]
      ? getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"slides"}
              src={content_icon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Sliders"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
          </Flex>,
          "slides"
        )
      : null,
    permissions["blog"]
      ? getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"blogs"}
              src={blog_icon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Blogs"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
          </Flex>,
          "blog"
        )
      : null,
    permissions["shop"]
      ? getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"shops"}
              src={shops_icon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Shops"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
          </Flex>,
          "shops"
        )
      : null,
    permissions["warehouse"]
      ? getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"warehouses"}
              src={warehouses_icon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Warehouses"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
            <Tag color={"red"} style={{ margin: "10px 0 0 10px" }}>
              Not ready
            </Tag>
          </Flex>,
          "warehouses"
        )
      : null,
    permissions["story"]
      ? getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"story"}
              src={story_icon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Stories"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
            <Tag color={"red"} style={{ margin: "10px 0 0 10px" }}>
              Not ready
            </Tag>
          </Flex>,
          "stories"
        )
      : null,
    permissions["notification"]
      ? getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"notification"}
              src={notification_icon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Notifications"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
            <Tag color={"orange"} style={{ margin: "10px 0 0 10px" }}>
              In progress
            </Tag>
          </Flex>,
          "notifications"
        )
      : null,
    permissions["offers"]
      ? getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"notification"}
              src={best_offers_icon}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"Best offers"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
            <Tag color={"red"} style={{ margin: "10px 0 0 10px" }}>
              Not ready
            </Tag>
          </Flex>,
          "best_offers"
        )
      : null,
  ]),

  permissions["api-version"]
    ? getItem("", "app", <img alt={"slides"} src={app_versions} />, [
        getItem(
          <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
            <img
              alt={"app_versions"}
              src={app_versions}
              style={{ margin: "8px 12px 0 0" }}
            />
            <Typography
              text={"App Version"}
              size={"14px"}
              variant={"p"}
              color={"#3B5166"}
              lineHeight={"20px"}
              padding={"10px 0 0 0"}
            />
          </Flex>,
          "app-versions"
        ),
      ])
    : null,

  getItem("", "sett", <img alt={"action"} src={settings_icon} />, [
    getItem(
      <Flex justify={"flex-start"} cursor={"pointer"} padding={"8px 0 0 0"}>
        <img
          alt={"settings"}
          src={settings_icon}
          style={{ margin: "8px 12px 0 0" }}
        />
        <Typography
          text={"Settings"}
          size={"14px"}
          variant={"p"}
          color={"#3B5166"}
          lineHeight={"20px"}
          padding={"10px 0 0 0"}
        />
      </Flex>,
      "settings"
    ),
  ]),

  getItem("", "usr", <div className={"_user-avatar"} />, [
    getItem(
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <div>
          <Typography
            text={name || ""}
            fontWeight={"500"}
            variant="span"
            size="14px"
            padding="0 0 0 0"
            color="rgba(59, 81, 102, 1)"
            weight="400"
          />
          <Typography
            text={email || ""}
            fontWeight={"500"}
            variant="span"
            size="14px"
            padding="0 15px 0 0"
            color="rgba(59, 81, 102, 1)"
            weight="400"
          />
        </div>
        <img alt={"logout"} src={logout_icon} />
      </Flex>,
      "user"
    ),
  ]),
];

export default items;
