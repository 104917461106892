import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import BalanceTopActions from "./TopActions";
import BalanceTable from "./Table";
import { useState } from "react";
import Flex from "../../components/general/Flex.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import { useBalancesList } from "../../hooks/Balances/useBalances.hook.jsx";
import isPrime from ".././../assets/images/prime.svg";
import { AddBankTransferModal } from "./AddBankTransferModal.jsx";
import { usersAutocomplete } from "../../hooks/useUsersAutocomplete.hook.js";

const StyledBalance = styled.div`
  padding-top: 24px;

  .bank_transfer {
    width: calc(100% - 30px);
    background: #ffffff;
    border-radius: 12px;
    padding: 8px 16px;
    margin-top: 24px;
  }
`;

export default function Balance({ permissions }) {
  const [filterData, setFilterData] = useState({});
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const { usersList = [] } = usersAutocomplete(
    userInfo && userInfo?.user_info?.trim()?.length === 0 ? null : userInfo
  );
  const {
    balancesList = [],
    meta,
    isLoading,
    refetch
  } = useBalancesList(filterData);

  return (
    <StyledBalance>
      <div className="page-title">
        <Typography
          text="Balance"
          color="rgba(38, 38, 38, 1)"
          weight={500}
          size="24px"
          variant="h2"
          margin="0 0 24px 0"
        />
      </div>
      <BalanceTopActions
        setFilterData={setFilterData}
        setUserInfo={setUserInfo}
        usersList={usersList}
        userInfo={userInfo}
      />

      {filterData?.user_id ? (
        <Flex
          className={"bank_transfer"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            variant={"h4"}
            text={
              (balancesList[0]?.user?.recipient?.first_name ||
                usersList[0]?.first_name ||
                usersList[0]?.company_name ||
                "") +
              " " +
              (balancesList[0]?.user?.recipient?.last_name ||
                usersList[0]?.last_name ||
                "") +
              " " +
              (balancesList[0]?.user?.recipient?.user_code ||
                usersList[0]?.user_code ||
                "")
            }
            weight={500}
          />
          {balancesList[0]?.user?.is_prime || usersList[0]?.user?.is_prime ? (
            <img src={isPrime} style={{ margin: "0 auto 0 5px" }} />
          ) : (
            ""
          )}
          <ButtonSecondary
            height={"36px"}
            text={"Add bank transfer"}
            background={"#5B6D7F"}
            color={"#ffffff"}
            click={() => setOpen(true)}
          />
        </Flex>
      ) : (
        ""
      )}
      <BalanceTable
        params={filterData}
        balancesList={balancesList}
        meta={meta}
        isLoading={isLoading}
        refetch={refetch}
        setFilterData={setFilterData}
        permissions={permissions}
      />
      <AddBankTransferModal
        open={open}
        setOpen={setOpen}
        user={balancesList[0]?.user}
        recipient={usersList[0]}
        refetch={refetch}
      />
    </StyledBalance>
  );
}
