import { AutoComplete, DatePicker, Drawer, Form, Input, Select } from "antd";
import styled from "styled-components";
import Typography from "../../components/moleculs/Typography.jsx";
import Flex from "../../components/general/Flex.jsx";
import ButtonPrimary from "../../components/moleculs/ButtonPrimary.jsx";
import ButtonSecondary from "../../components/moleculs/ButtonSecondary.jsx";
import {
  openNotification,
  openNotificationError,
} from "../../helpers/notification.js";
import { useState } from "react";
import { usersAutocomplete } from "../../hooks/useUsersAutocomplete.hook.js";
import { useGetBonusTypeLis } from "../../hooks/bonus/useGetBonusTypeList.hook.jsx";
import { useCreateBonus } from "../../hooks/bonus/useCreateBonus.hook.js";
import dayjs from "dayjs";

const WrapperModal = styled.div`
  .auto_comp {
    width: 100%;
  }

  .ant-form-item-label {
    padding: 0;
  }

  .ant-form-item {
    margin-bottom: 10px !important;
  }

  label {
    font-size: 14px;
    color: #3b5166 !important;

    :before {
      display: none !important;
    }
  }

  .card {
    box-shadow: none;
    display: grid;
    gap: 16px;
    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
    background: #ffffff;
    border-radius: 12px;
    padding: 16px;
    margin: 24px 0;
  }
`;

export default function CreateBonusModal({
  status,
  setStatus,
  setCreateRole,
  reFetch = () => {},
}) {
  const [form] = Form.useForm();
  const [userInfo, setUserInfo] = useState(null);
  const [selectId, setSelectId] = useState(null);
  const { usersList = [] } = usersAutocomplete(userInfo);
  const { bonusTypeList = [] } = useGetBonusTypeLis();
  const { mutate } = useCreateBonus(
    (data) => {
      openNotification("Create bonus", "Bonus successfully created");
      reFetch();
      setStatus(false);
      form.resetFields();
    },
    (e) => {
      openNotificationError("Create bonus", "something went wrong");
    }
  );
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  const c = new Date(year + 1, month, day);

  const onFinish = ({ user_id, date, ...value }) => {
    const expire_date = date
      ? dayjs(date).format("YYYY-MM-DD")
      : dayjs(c).format("YYYY-MM-DD");

    mutate({
      user_id: selectId,
      type: "in",
      expire_date: expire_date,
      ...value,
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Drawer
      title=""
      placement="right"
      onClose={() => setStatus(false)}
      open={status}
      width={800}
      header={false}
    >
      <WrapperModal>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{ date: dayjs(c) }}
          onFinishFailed={onFinishFailed}
        >
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Typography
              variant={"h2"}
              text={"Create bonus"}
              weight={"500"}
              size={"22px"}
            />
            <Flex alignItems={"center"}>
              <ButtonSecondary
                text="Cancel"
                background="#FFECED"
                color="#FC4447"
                border={"none"}
                htmlType="reset"
                style={{ margin: "0 16px" }}
                click={() => setStatus(false)}
              />
              <ButtonPrimary text="Save" border={"none"} htmlType="submit" />
            </Flex>
          </Flex>
          <div className={"card"}>
            <Form.Item
              name="user_id"
              rules={[{ required: true }]}
              label={"User code"}
            >
              <AutoComplete
                placeholder="User code"
                onSearch={(val, el) => setUserInfo({ user_info: val })}
                onSelect={(_, el) => {
                  setSelectId(el?.user_id);
                }}
                options={usersList}
              />
            </Form.Item>
            <Form.Item
              name="bonus_type_id"
              rules={[{ required: true }]}
              label={"Bonus type"}
            >
             <Select options={bonusTypeList?.filter(tp=>tp.label !=="Cashback") ||[]} placeholder={"Bonus type"} />
            </Form.Item>
            <Form.Item
              name="sum"
              rules={[{ required: true }]}
              label={"Bonus amount"}
            >
              <Input placeholder={"Bonus amount"} />
            </Form.Item>
            <Form.Item
              name="date"
              rules={[{ required: true }]}
              label={"Expire date"}
            >
              <DatePicker
                placeholder={"Expire date"}
                style={{ width: "100%" }}
                disabledDate={(d) => !d || d.isBefore(Date.now())}
              />
            </Form.Item>
          </div>
        </Form>
      </WrapperModal>
    </Drawer>
  );
}
